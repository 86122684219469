export const loadVueComponent = async (
    selector,
    chunk,
    el,
    componentName,
    hasTemplate
) => {
    const componentDomInstance = document.querySelector(selector)

    if (componentDomInstance) {
        if (!hasTemplate)
            componentDomInstance.setAttribute('inline-template', '')

        const Vue = await import(/* webpackChunkName: 'vue' */ 'vue')
        const loadedComponent = await chunk()

        new Vue.default({
            el,
            delimiters: ['[[', ']]'],
            components: {
                [componentName]: loadedComponent.default,
            },
        })
    }
}
